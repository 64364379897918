import { UserInfo } from "./models";

export default class AuthService {
  login() {
    // TODO: call Api to login...get token and user id back
  }

  handleAuthentication(history: any) {
    // this.auth0.parseHash((err: any, authResult: any) => {
    //     if (authResult && authResult.accessToken && authResult.idToken) {
    //         // console.log(authResult);
    //         this.setSession(authResult);
    //         history.push("/");
    //     } else if (err) {
    //         console.log(err);
    //     }
    // });
    // TODO: remove?
  }

  setSession(authResult: any) {
    // let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    // localStorage.setItem('access_token', authResult.accessToken);
    // localStorage.setItem('id_token', authResult.idToken);
    // localStorage.setItem('expires_at', expiresAt);
  }

  isAuthenticated() {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    return true;
  }

  getToken() {
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    const tokenFromQuerystring = params.get("token");
    const token = localStorage.getItem("token") || tokenFromQuerystring;
    // if (!token) {
    //     throw new Error('No token found');
    // }
    return token;
  }

  // TODO: test and keep?
  static _parseJwt(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  getUserInfo() {
    const token = this.getToken();

    const payload = token ? AuthService._parseJwt(token) : {};
    // console.log(payload);
    // TODO: get only user id from token....!!!!
    const { /* given_name, family_name, */ nickname, picture, locale, userId } = payload;
    return { /* given_name, family_name, */ name: nickname, nickname, picture, locale, userId } as UserInfo;
  }

  logout() {
    localStorage.removeItem("token");
    window.location.href = "/";
  }
}
