import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import registerServiceWorker from './registerServiceWorker';
import { loadTheme } from 'office-ui-fabric-react/lib/Styling';
import { setMetaThemeColor } from './helpers';
import { useTheme } from "./useTheme";
import './i18n';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const theme = useTheme();
loadTheme(theme);
setMetaThemeColor(theme.palette.themePrimary);

const styles = `
  a {
    color: ${theme.palette.themePrimary};
    /* Add any other styles you want here */
  }
`;

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
ReactDOM.render(
   <React.StrictMode>
      <MsalProvider instance={msalInstance}>
         <BrowserRouter basename={baseUrl}>
            <style>{styles}</style>
            <App />
         </BrowserRouter>
      </MsalProvider>
   </React.StrictMode>,
   rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
registerServiceWorker();

